import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "./Header.scss"

const Header: React.FC = () => (
  <nav className="nav">
    <Link className="logo" to="/">
      IDEAS Lab
    </Link>
    <div className="menu">
      <Link activeClassName="active" to="/">
        Home
      </Link>
      <Link activeClassName="active" to="/members">
        People
      </Link>
      <Link activeClassName="active" to="/publications">
        Publications
      </Link>
      <Link activeClassName="active" to="/events">
        Events
      </Link>
      <Link activeClassName="active" to="/seminars">
        Seminars
      </Link>
      <OutboundLink target="_blank" href="https://github.com/Ideas-Laboratory">
        {"Code & Data"}
      </OutboundLink>
    </div>
  </nav>
)

export default Header

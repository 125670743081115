import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"
import "./scroll-to-top.scss"

let intervalId = null
let pixelPerStep = null
const scrollTime = 300
const stepCount = 60
const intervalPerStep = scrollTime / stepCount

const scrollToTop = () => {
  if (intervalId === null) {
    pixelPerStep = window.pageYOffset / stepCount
    intervalId = setInterval(() => {
      window.scroll(0, window.pageYOffset - pixelPerStep)
      if (window.pageYOffset <= 0) {
        clearInterval(intervalId)
        intervalId = null
      }
    }, intervalPerStep)
  }
}

const ScrollToTop = () => {
  return (
    <button title="Scroll to top" className="scroll-to-top" onClick={scrollToTop}>
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  )
}

export default ScrollToTop

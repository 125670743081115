import React from "react"
import PropTypes from "prop-types"
import ScrollToTop from "./scroll-to-top"
import Footer from "./Footer"
import Header from "./Header"
import "normalize.css"
import "./common.scss"
import "./all.scss"

const Layout = ({ className = "", children }) => {
  return (
    <>
      <Header />
      <main className={`main ${className}`}>{children}</main>
      <Footer year={new Date().getFullYear()} />
      <ScrollToTop />
    </>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"
import PropTypes from "prop-types"
import "./Footer.scss"

export type FooterProps = {
  year: number
}

const Footer: React.FC<FooterProps> = ({ year }) => {
  return (
    <footer className="footer">
      <div>Copyright &copy; IDEAS Lab {year}</div>
      <div>Shandong Univeristy, Qingdao, China</div>
      <a href="https://clustrmaps.com/site/1b0sr" title="Visit tracker">
        <img
          alt="Visitor Map powered by ClustrMaps"
          className="clustr-maps"
          src="//www.clustrmaps.com/map_v2.png?d=fzDR3W_306MYaop7kzYyMBZ6OBxj_e73-pgZDvusnNE&cl=ffffff"
        />
      </a>
    </footer>
  )
}

Footer.propTypes = {
  year: PropTypes.number.isRequired,
}

export default Footer
